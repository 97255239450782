<script setup lang="ts">
import InsightsChart from '~/components/chart/InsightsChart.vue';

const spellsStore = useSpellsStore();
const { trigger } = storeToRefs(spellsStore);
</script>
<template>
  <NuxtLayout name="spell-tab" :wide="true">
    <SpellHeader v-if="trigger" />

    <p class="title">Insights</p>
    <InsightsChart short class="my-m" />

    <p class="title">History</p>
    <div
      class="border-container mt-m p-m bg-white w-full flex flex-col rounded-2xl items-start"
    >
      <HistoryTable
        :fields="['status', 'startedAt', 'runtime', 'cost', 'result']"
        :page-size="5"
      />
    </div>
  </NuxtLayout>
</template>
